import React from "react";
import Grid from "@toast-ui/react-grid";
import "../../../assets/lib/tui/tui-grid.css";
import { useNavigate } from "react-router-dom";
import TuiGrid from "tui-grid";
import { LanguageContext } from "../../../context/LanguageContext";
import tableStyle from "./RunStyledTable";
import { add_hyphen, trimType } from "../../../../src/lib/common_func";
import { useContext, useRef } from "react";
import { ISODateToDateTime } from "../../../lib/common_func";
import DeviceLogMainExcel, { DevicelogmainExcel } from "../pages/DeviceLogMainExcel";
function Table({ data, tableRef }) {
  // context state
  const { language } = useContext(LanguageContext);

  // variable
  const header = {
    //tui grid
    height: 50,
  };
  const columns = [
    {
      header: language === "KOR" ? "단말번호" : "USIM num",
      name: "unitid",
      align: "center",
      sortable: true,
      formatter: (item) => add_hyphen(item.value, language),
    },
    {
      header: language === "KOR" ? "단말기명" : "Device name",
      name: "unit_nm",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "기사" : "Driver",
      name: "driver_nm",
      sortable: true,
      width: 90,
    },
    {
      header: language === "KOR" ? "일일 주행거리(km)" : "Today(km)",
      name: "day_dist",
      align: "right",
      sortable: true,
      formatter: (item) => item.value.toFixed(2),
    },
    {
      header: language === "KOR" ? "누적 주행거리(km)" : "Total(km)",
      name: "total_dist",
      align: "right",
      sortable: true,
      formatter: (item) => item.value.toFixed(2),
    },
    {
      header: language === "KOR" ? "최종 수신일시" : "Last received",
      name: "date",
      align: "center",
      sortable: true,
      formatter: (item) =>
        item.value === null ? "" : ISODateToDateTime(item.value),
    },
    {
      header: language === "KOR" ? "운행기록 1" : "Log view 1",
      name: "btn1",
      align: "center",
      width: 100,
      formatter: () =>
        `<div class='link cursor'>${
          language === "KOR" ? "기간별" : "periodic"
        }</div>`,
      //console.log(trimType(value.row.unitid));
      //return <a href="#" className="link">1</a>
    },
    {
      header: language === "KOR" ? "운행기록 2" : "Log view 2",
      name: "btn2",
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "일별" : "daily"
        }</div>`,
      width: 100,
    },
  ];
  const bodyHeight = data.length < 10 ? data.length * 50 : 520;

  // Hook
  TuiGrid.applyTheme(null, tableStyle);
  const navi = useNavigate();
  const gridRef = useRef();

  // function
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    const unitid = trimType(e.instance.store.data.rawData[i].unitid);
    const unit_nm = e.instance.store.data.rawData[i].unit_nm;
    if (e.targetType != "columnHeader") {
      switch (e.columnName) {
        case "btn1":
          // console.log(unitid);
          navi(`/Dlog/Term`, { state: { unitid, unit_nm } });
          break;
        case "btn2":
          // console.log(unitid);
          navi(`/Dlog/Daily`, { state: { unitid, unit_nm } });
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className="common-table-wrapper">
      <div className="common-grid">
        <Grid
          ref={gridRef}
          data={data}
          columns={columns}
          rowHeight={50}
          rowHeaders={[{ type: "rowNum" }]}
          scrollX={false}
          scrollY={true}
          bodyHeight={bodyHeight}
          header={header}
          onClick={move}
        />
        <DeviceLogMainExcel data={data} tableRef={tableRef} />
      </div>
    </div>
  );
}

export default Table;
